import { jwtDecode } from "jwt-decode";
import moment from 'moment';
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import SnackbarContext from '../Context/SnackbarContext';

const AuthContext = React.createContext({
  token: false,
  isLoggedIn: false,
  customerId: '',
  registerCustomer: (id) => { },
  login: (token) => { },
  logout: () => { },
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const id = localStorage.getItem("customerId");
  const { openSnackbar } = useContext(SnackbarContext);
  const [token, setToken] = useState(initialToken);
  const [customerId, setCustomerId] = useState(id);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  let location = useLocation();
  let navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000;
      const expirationDate = new Date(expirationTime);
      const timeNow = Date.now();

      const isExpired = Date.now() >= expirationTime;
      if (!isExpired) {
        setUserIsLoggedIn(true);
      } else {
        setUserIsLoggedIn(false);
        openSnackbar("Your session expired - please login", "error");
        localStorage.removeItem("token");
        setToken(null);
        routeChange("/login");
      }
    }
  }, [token, location]);

  const registerCustomer = (customerId) => {
    localStorage.setItem("customerId", customerId);
    setCustomerId(customerId);
  }

  const loginHandler = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    customerId: customerId,
    registerCustomer: registerCustomer,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;