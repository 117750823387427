import Decimal from 'decimal.js';
import { GlobalVariables, InsurancePlan, PremiumRate, PremiumRates } from './InsuranceModels';

const getRateForAge = (age: number, rates: PremiumRate[], globals: GlobalVariables): Decimal | null => {
  const loadingsSum = new Decimal(globals.grFee)
    .plus(globals.triarcBinder)
    .plus(globals.commission)
    .plus(globals.margin);

  for (const rate of rates) {
    if (rate.ageCategory === '85+') {
      if (age >= 85) return new Decimal(rate.rate).dividedBy(new Decimal(1).minus(loadingsSum.dividedBy(100)));
    } else {
      const [minAge, maxAge] = rate.ageCategory.split('-').map(Number);
      if (age >= minAge && age <= maxAge) {
        return new Decimal(rate.rate).dividedBy(new Decimal(1).minus(loadingsSum.dividedBy(100)));
      }
    }
  }
  return null;
}

const CalculatePremium = (insurancePlan: InsurancePlan, rates: PremiumRates, globals: GlobalVariables): InsurancePlan => {

  let mainMemberRate = getRateForAge(insurancePlan.Principal.age, rates.mainMemberRates, globals);

  if (mainMemberRate !== null) {
    let premium = new Decimal(insurancePlan.Principal.coverAmount).dividedBy(1000).times(mainMemberRate);

    premium = premium.plus(globals.repatIndividual);
    insurancePlan.Principal.premiumAmount = new Decimal(premium);

    let isFamilySelected = insurancePlan.Family.Spouse.selected || insurancePlan.Family.Children.selected;
    let isParentsSelected = insurancePlan.Parent.selected;
    let isExtendedFamilySelected = insurancePlan.ExtendedFamily.selected;

    if (isFamilySelected) {
      mainMemberRate = getRateForAge(insurancePlan.Principal.age, rates.mainMemberRates, globals);
      let spouseRate = getRateForAge(insurancePlan.Principal.age, rates.spouseRates, globals);
      let childrenRate = getRateForAge(insurancePlan.Principal.age, rates.childrenRates, globals);

      let sumOfRates = new Decimal(mainMemberRate).plus(spouseRate).plus(childrenRate);
      let premiumWaiver = getRateForAge(insurancePlan.Principal.age, rates.spousePremiumWaiverRates, globals);

      premium = new Decimal(insurancePlan.Principal.coverAmount).dividedBy(1000).times(sumOfRates).times(new Decimal(1).plus(premiumWaiver));
      premium = premium.plus(globals.repatFamily);
      insurancePlan.Family.premiumAmount = premium;
    }

    if (isParentsSelected) {
      if (mainMemberRate !== null) {

        const updatedParents = insurancePlan.Parent.parents.map(parent => {
          const rateForParent = getRateForAge(parent.age, rates.mainMemberRates, globals);
          const premiumForParent = new Decimal(parent.coverAmount).dividedBy(1000).times(rateForParent);
          return {
            ...parent, 
            premiumAmount: premiumForParent 
          };
        });

        insurancePlan = {
          ...insurancePlan,
          Parent: {
            ...insurancePlan.Parent,
            parents: updatedParents 
          }
        };

        premium = updatedParents.reduce((acc, curr) => acc.plus(curr.premiumAmount || new Decimal(0)), premium);
    
      } else {
        console.log("Main member rate is not available.");
      }
    }

    if (isExtendedFamilySelected) {
      if (mainMemberRate !== null) {

        const updatedExtendedFamily = insurancePlan.ExtendedFamily.extendedMembers.map(extendedFamily => {
          const rateForExtendedFamily = getRateForAge(extendedFamily.age, rates.extendedFamilyRates, globals);
          const premiumForExtendedFamily = new Decimal(extendedFamily.coverAmount).dividedBy(1000).times(rateForExtendedFamily);
          return {
            ...extendedFamily, 
            premiumAmount: premiumForExtendedFamily 
          };
        });

        insurancePlan = {
          ...insurancePlan,
          ExtendedFamily: {
            ...insurancePlan.ExtendedFamily,
            extendedMembers: updatedExtendedFamily 
          }
        };

        premium = updatedExtendedFamily.reduce((acc, curr) => acc.plus(curr.premiumAmount || new Decimal(0)), premium);
    
      } else {
        console.log("Main member rate is not available.");
      }
    }

    insurancePlan.totalPremiumAmount = premium;
    return insurancePlan;
  }
}

export { CalculatePremium };