import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { To, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import AuthContext from '../Context/AuthContext';
import SnackbarContext from '../Context/SnackbarContext';
import FormInput from '../components/Form/FormInput';
import FormSelect from '../components/Form/FormSelect';
import SharedButton from '../components/Shared/SharedButton/SharedButton';
import { Agents_LoginRequest, BankingDetailCreate_Request, Client, Create_Request, Create_Response, CustomerBankingDetailEntity_DebitOrderDays, IAgents_LoginRequest, IBankingDetailCreate_Request, ICreate_Request, ICreate_Request_ForeignNationalDto } from '../api/insurance-api.ts';

import { bankingSchema } from '../validation/schema';
import SharedSwitch from '../components/Shared/SharedSwitch/SharedSwitch';
import { useLoader } from '../Context/LoadingContext.jsx';

const Banking: React.FC = () => {
    const { customerId } = useContext(AuthContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const { showLoader, hideLoader } = useLoader();
    const apiclient = new Client(import.meta.env.VITE_API_URL);

    let navigate = useNavigate();

    const routeChange = (path: To) => {
        navigate(path);
    };

    type BankingInputs = z.infer<typeof bankingSchema>;

    const { control, formState: { errors }, register, handleSubmit, setError, clearErrors, getValues } = useForm({
        mode: 'onChange',
        resolver: zodResolver(bankingSchema)
    });

    const onSubmit: SubmitHandler<IBankingDetailCreate_Request> = async (data) => {

        let requestBanking: IBankingDetailCreate_Request = {
            premiumStartDate: new Date(data.premiumStartDate),
            debitOrderDay: data.debitOrderDay,
            accountHolderName: data.accountHolderName,
            accountNumber: data.accountNumber,
            bankName: data.bankName,
            branchCode: data.branchCode,
            accountType: data.accountType
        };

        try {
            showLoader();
            const response = await apiclient.customerBankingDetailCreate(customerId, new BankingDetailCreate_Request(requestBanking));
            openSnackbar("Banking details saved ", "success");
            routeChange("/sign");

        } catch (error) {
            openSnackbar("Failed - please try again", "error");
        }
        finally {
            hideLoader();
        }
    };

    return (
        <>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h3'>Banking Details</Typography>
                    <Typography variant='h5'>Please enter your banking details</Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <Typography variant='body2'>Premium Start date</Typography>
                                <FormInput
                                    name="premiumStartDate" // change
                                    control={control}
                                    label=""
                                    type="date"
                                    error={!!errors.premiumStartDate}
                                    helperText={errors.premiumStartDate?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="accountHolderName"
                                    control={control}
                                    label="Account Holder Name"
                                    error={!!errors.accountHolderName}
                                    helperText={errors.accountHolderName?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="accountType"
                                    control={control}
                                    label="Account Type"
                                    error={!!errors.accountType}
                                    helperText={errors.accountType?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="accountNumber"
                                    control={control}
                                    label="Account Number"
                                    error={!!errors.accountNumber}
                                    helperText={errors.accountNumber?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="branchCode"
                                    control={control}
                                    label="Branch Code"
                                    error={!!errors.branchCode}
                                    helperText={errors.branchCode?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="bankName"
                                    control={control}
                                    label="Bank Name"
                                    error={!!errors.bankName}
                                    helperText={errors.bankName?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormSelect
                                    name="debitOrderDay"
                                    control={control}
                                    label="Debit Order Start Date"
                                    options={[
                                        { label: "1st", value: CustomerBankingDetailEntity_DebitOrderDays._1 },
                                        { label: "7th", value: CustomerBankingDetailEntity_DebitOrderDays._7 },
                                        { label: "15th", value: CustomerBankingDetailEntity_DebitOrderDays._15 },
                                        { label: "20th", value: CustomerBankingDetailEntity_DebitOrderDays._20 },
                                        { label: "25th", value: CustomerBankingDetailEntity_DebitOrderDays._25 },
                                        { label: "26th", value: CustomerBankingDetailEntity_DebitOrderDays._26 },
                                        { label: "27th", value: CustomerBankingDetailEntity_DebitOrderDays._27 },
                                        { label: "30th", value: CustomerBankingDetailEntity_DebitOrderDays._30 }
                                    ]}
                                    error={Boolean(errors.debitOrderDay)}
                                    helperText={errors.debitOrderDay?.message?.toString() || "Debit Order start day"}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="center" alignItems="center" marginTop={'20px'} marginBottom={"40px"}>
                            <SharedButton type={'submit'} text={`Submit`}></SharedButton>
                        </Box>
                        <>
                            {Object.keys(errors).map(x => {
                                console.log("x", x);
                            })}
                        </>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Banking;
