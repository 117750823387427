import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { AuthContextProvider } from './Context/AuthContext';
import Base from './pages/Base';
import Home from './pages/Home';
import theme from './theme';
import { InsuranceCalculatorProvider } from './Context/Calculator/InsuranceCalculatorContext';
import Register from './pages/Register';
import Address from './pages/Address';
import PolicyDetails from './pages/PolicyDetails/PolicyDetails';
import Banking from './pages/Banking';
import Compliance from './pages/Compliance';
import Login from './pages/Login';
import { SnackbarProvider } from './Context/SnackbarContext';
import LoaderProvider from './Context/LoadingContext';
import TermsAndConditions from './pages/TermsAndConditions';
import BaseCalculator from './components/BaseCalculator/BaseCalculator';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { TrackingContextProvider } from './Context/TrackingContext';

function App() {

  return (
    <>
      <ThemeProvider theme={theme} >
        <SnackbarProvider>
          <LoaderProvider>
            <TrackingContextProvider>
              <AuthContextProvider>
                <InsuranceCalculatorProvider>
                  {/* <BrowserRouter> */}
                  <Routes>
                    <Route path="/" element={<Base />}>
                      <Route path="/" element={<Home />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/address" element={<Address />} />
                      <Route path="/compliance" element={<Compliance />} />
                      <Route path="/policy" element={<PolicyDetails />} />
                      <Route path="/banking" element={<Banking />} />
                      <Route path="/sign" element={<TermsAndConditions />} />
                      <Route path="/base" element={<BaseCalculator />} />
                    </Route>
                  </Routes>
                  {/* </BrowserRouter> */}
                </InsuranceCalculatorProvider>
              </AuthContextProvider >
            </TrackingContextProvider>
          </LoaderProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  )
}

export default App


