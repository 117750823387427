import React, { useContext } from 'react'
import Header from '../components/Header/Header'
import { Navigate, Outlet } from 'react-router-dom'
import AuthContext from '../Context/AuthContext';

const Base: React.FC = () => {

    // const { isLoggedIn } = useContext(AuthContext);
    // console.log("🚀 ~ isLoggedIn:", isLoggedIn)

    // if (!isLoggedIn) {
    //     return <Navigate to="/login" />;
    // }

    return (
        <>
            <Header />
            {/* <div className="content-container"> */}
                <Outlet />
            {/* </div> */}
            {/* <Footer /> */}
        </>
    )
}

export default Base