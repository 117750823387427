import { Button, styled } from '@mui/material';
import React from 'react';

interface SharedButtonProps {
  text: string;
  handleOnClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  border: 0,
  borderRadius: 10,
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  color: "white",
  height: 48,
  padding: "0 30px",
  "&:hover": {
    background: theme.palette.primary.light
  },
  width: '200px',
}));

const SharedButton: React.FC<SharedButtonProps> = ({
  text,
  handleOnClick = () => { },
  type = 'button',
  disabled = false,
}) => {
  return (
    <StyledButton
      type={type}
      disabled={disabled}
      onClick={handleOnClick}
      variant="contained"
    >
      {text}
    </StyledButton>
  );
};

export default SharedButton;
