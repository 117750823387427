import Switch from '@mui/material/Switch';

// const useStyles = styled({
//     root: {
//         width: 41,
//         height: 24,
//         padding: 0,
//         display: 'flex',
//     },
//     switchBase: {
//         padding: 3,
//         color: '#00000',
//         '&$checked': {
//             transform: 'translateX(16px)',
//             color: '#fff',
//             '& + $track': {
//                 backgroundColor: theme.colors.primary,
//                 opacity: 1,
//                 border: 'none',
//             },
//         },
//     },
//     thumb: {
//         width: 18,
//         height: 18,
//         boxShadow: 'none',
//     },
//     track: {
//         borderRadius: 24 / 2,
//         border: '1px solid #9E9E9E',
//         backgroundColor: '#9E9E9E',
//         opacity: 1,
//         transition: 'background-color 1s, border 0.2s',
//     },
//     checked: {},
// });

const SharedSwitch = ({toggle, checked}) => {

    // const classes = useStyles();

    return (
        <>
            <Switch
                // classes={{
                //     root: classes.root,
                //     switchBase: classes.switchBase,
                //     thumb: classes.thumb,
                //     track: classes.track,
                //     checked: classes.checked,
                // }}
                checked={checked}
                onChange={toggle}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        </>
    )
}

export default SharedSwitch