import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import AuthContext from '../../Context/AuthContext';
import { useInsuranceCalculator } from '../../Context/Calculator/InsuranceCalculatorContext';
import SnackbarContext from '../../Context/SnackbarContext';
import { CustomerBeneficiaryEntity_BeneficiaryType, CustomerDependantEntity_DependantType } from '../../api/insurance-api';
import SharedButton from '../../components/Shared/SharedButton/SharedButton';
import Beneficiary from './Beneficiary';
import Dependent from './Dependent';

const PolicyDetails: React.FC = () => {

    const { insurancePlan, setInsurancePlan, calculatePremium } = useInsuranceCalculator();
    const authContext = useContext(AuthContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const [completedForms, setCompletedForms] = useState([]);
    const [isFormsCompleted, setIsFormsCompleted] = useState(false);
    const [accordianKeys, setAccordianKeys] = useState([]);
    const [requiredForms, setRequiredForms] = useState([]);
    const [allFormCompleted, setAllFormCompleted] = useState<boolean>();

    const [showPassportNumber, setShowPassportNumber] = useState(false);
    const [expanded, setExpanded] = useState<string>();

    const [loader, setLoader] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        const storedCompletedForms = JSON.parse(localStorage.getItem('completedForms') || '[]');
        setCompletedForms(storedCompletedForms);
    }, []);

    useEffect(() => {
        localStorage.setItem('completedForms', JSON.stringify(completedForms));
    }, [completedForms]);

    useEffect(() => {
        const newRequiredForms = [];

        newRequiredForms.push('Beneficiary');

        if (insurancePlan?.Family?.Spouse?.selected) {
            newRequiredForms.push('Spouse');
        }

        if (insurancePlan?.Family?.Children?.selected) {
            for (let i = 0; i < insurancePlan.Family.Children.amount; i++) {
                newRequiredForms.push(`Child${i}`);
            }
        }

        if (insurancePlan?.Parent?.selected) {
            insurancePlan.Parent.parents.forEach((parent, index) => {
                newRequiredForms.push(`Parent${parent.id}`);
            });
        }

        if (insurancePlan?.ExtendedFamily?.selected) {
            insurancePlan.ExtendedFamily.extendedMembers.forEach((member, index) => {
                newRequiredForms.push(`Extended${member.id}`);
            });
        }

        setRequiredForms(newRequiredForms);
    }, [insurancePlan]);

    useEffect(() => {
        const optionalForms = ['secondaryBeneficiary']; // Add more optional form IDs as needed
    
        function arraysMatch(required, completed, optional) {
            const requiredSet = new Set(required.filter(item => !optional.includes(item))); // Remove optional fields from required
            const completedSet = new Set(completed); // Keep all completed as they are
    
            // Check if every required (non-optional) form is completed
            for (let item of requiredSet) {
                if (!completedSet.has(item)) return false;
            }
            return true;
        }
    
        const doTheyMatch = arraysMatch(requiredForms, completedForms, optionalForms);
        setAllFormCompleted(doTheyMatch);
    
    }, [requiredForms, completedForms]);
    

    const routeChange = (path: To) => {
        navigate(path);
    };

    const handleExpandedChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCompletedForms = (formId) => {
        setCompletedForms(prevCompletedForms => {
            if (!prevCompletedForms.includes(formId)) {
                return [...prevCompletedForms, formId];
            }

            return prevCompletedForms;
        });

        setExpanded('');
    };


    // const onSubmit: SubmitHandler<IAddressDetails> = async (data) => {

    // };

    useEffect(() => {
        console.log(requiredForms);
        console.log(completedForms);
    }, [completedForms, requiredForms])



    const generateBeneficiaryForms = () => {
        const isBeneficiaryCompleted = completedForms.some(id => completedForms.includes("Beneficiary"));
        const isSecondaryBeneficiaryCompleted = completedForms.some(id => completedForms.includes("SecondaryBeneficiary"));
        return (
            <>
                <Accordion
                    expanded={expanded === "Beneficiary"}
                    onChange={handleExpandedChange("Beneficiary")}
                    disabled={isBeneficiaryCompleted}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Nominated Beneficiary
                        </Typography>
                        {
                            isBeneficiaryCompleted && (
                                <Typography sx={{ color: 'text.secondary' }}>Completed</Typography>
                            )
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <Beneficiary setCompletedForms={handleCompletedForms} type={CustomerBeneficiaryEntity_BeneficiaryType.PrimaryBeneficiary} title={`Beneficiary details`} form={"Beneficiary"}></Beneficiary>
                    </AccordionDetails>
                </Accordion>


                <Accordion
                    expanded={expanded === "SecondaryBeneficiary"}
                    onChange={handleExpandedChange("SecondaryBeneficiary")}
                    disabled={isSecondaryBeneficiaryCompleted}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Secondary Beneficiary
                        </Typography>

                        {
                            !isSecondaryBeneficiaryCompleted && (
                                <Typography sx={{ color: 'text.secondary' }}>Optional</Typography>
                            )
                        }
                        {
                            isSecondaryBeneficiaryCompleted && (
                                <Typography sx={{ color: 'text.secondary' }}>Completed</Typography>
                            )
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <Beneficiary setCompletedForms={handleCompletedForms} type={CustomerBeneficiaryEntity_BeneficiaryType.SecondaryBeneficiary} title={`Secondary Beneficiary details`} form={"SecondaryBeneficiary"}></Beneficiary>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    const generateSpouseForm = () => {

        const isSpouseCompleted = completedForms.includes("Spouse");

        if (!insurancePlan || !insurancePlan.Family || !insurancePlan.Family.Spouse || !insurancePlan.Family.Spouse.selected) {
            return (<div></div>)
        }

        return (
            <>
                <Accordion
                    key={"Spouse"}
                    expanded={expanded === "Spouse"}
                    onChange={handleExpandedChange("Spouse")}
                    disabled={isSpouseCompleted}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Spouse Details
                        </Typography>
                        {
                            isSpouseCompleted && (
                                <Typography sx={{ color: 'text.secondary' }}>Completed</Typography>
                            )
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* <Dependent setCompletedForms={handleCompletedForms} type={'Spouse'} title={`Spouse details`} form={"Spouse"}></Dependent> */}
                        <Dependent setCompletedForms={handleCompletedForms} type={CustomerDependantEntity_DependantType.Spouse} title={`Spouse details`} form={"Spouse"}></Dependent>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    const generateChildrenForms = () => {

        if (!(insurancePlan?.Family?.Children?.selected)) {
            return <div />;
        }

        const createChildForm = (index) => {
            const formId = `Child${index}`;
            const isFormCompleted = completedForms.includes(formId);

            return (
                <Accordion
                    key={index}
                    expanded={expanded === formId}
                    onChange={handleExpandedChange(formId)}
                    disabled={isFormCompleted}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Child {index + 1} Details
                        </Typography>
                        {isFormCompleted && (
                            <Typography sx={{ color: 'text.secondary' }}>Completed</Typography>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Dependent
                            setCompletedForms={handleCompletedForms}
                            type={CustomerDependantEntity_DependantType.Child}
                            title={`Child ${index + 1} details`}
                            form={formId}
                        />
                    </AccordionDetails>
                </Accordion>
            );
        };

        // Generate forms for the number of children specified in the insurance plan.
        return (
            <React.Fragment>
                {Array.from({ length: insurancePlan.Family.Children.amount }, (_, i) => createChildForm(i))}
            </React.Fragment>
        );
    };

    const generateParentForms = () => {

        if (!insurancePlan || !insurancePlan.Parent || !insurancePlan.Parent.parents || insurancePlan.Parent.parents.length === 0 || insurancePlan.Parent.selected == false) {
            return (<div></div>)
        }

        return insurancePlan.Parent.parents.map((parent, i) => {
            const formId = `Parent${parent.id}`;
            const isFormCompleted = completedForms.some(id => completedForms.includes(formId));

            return (
                <Accordion
                    expanded={expanded === parent.id}
                    onChange={handleExpandedChange(parent.id)}
                    disabled={isFormCompleted}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {parent.age} year old parent details
                        </Typography>
                        {
                            isFormCompleted && (
                                <Typography sx={{ color: 'text.secondary' }}>Completed</Typography>
                            )
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <Dependent setCompletedForms={handleCompletedForms} type={CustomerDependantEntity_DependantType.Parent} title={`${parent.age} year old parent details`} form={formId}></Dependent>
                    </AccordionDetails>
                </Accordion>
            )
        });
    }

    const generateExtendFamilyForms = () => {

        if (!insurancePlan || !insurancePlan.ExtendedFamily || !insurancePlan.ExtendedFamily.extendedMembers || !insurancePlan.ExtendedFamily.selected) {
            return (<div></div>)
        }

        return insurancePlan.ExtendedFamily.extendedMembers.map((extended, i) => {
            const formId = `Extended${extended.id}`;
            const isFormCompleted = completedForms.some(id => completedForms.includes(formId));

            return (
                <Accordion
                    expanded={expanded === extended.id}
                    onChange={handleExpandedChange(extended.id)}
                    disabled={isFormCompleted}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {extended.age} year old extended family details
                        </Typography>
                        {
                            isFormCompleted && (
                                <Typography sx={{ color: 'text.secondary' }}>Completed</Typography>
                            )
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <Dependent setCompletedForms={handleCompletedForms} type={CustomerDependantEntity_DependantType.ExtendedFamily} title={` ${extended.age} year old extended family details`} form={formId}></Dependent>
                    </AccordionDetails>
                </Accordion>
            )
        });
    }

    const handleSubmit = () => {
        setCompletedForms([]);
        localStorage.removeItem('completedForms');
        routeChange("/banking");
    };

    let policyContent = (

        <>
            <Typography variant='h3'>Policy Details</Typography>
            <Typography variant='h5'>Please enter your policy members details</Typography>
            <Box sx={{ mt: 3 }}>

                <Grid container spacing={2}>
                    {generateSpouseForm()}
                    {generateChildrenForms()}
                    {generateParentForms()}
                    {generateExtendFamilyForms()}
                </Grid>
            </Box>
        </>
    )

    let beneficiaryContent = (
        <>
            <Typography display={'flex'} justifyContent={"center"} variant='h3' marginTop={4}>Beneficiary Details</Typography>
            <Typography marginTop={2} variant='body1'>Please give us the details of the person to whom the benefits will be paid on the death of the main member. If any of the other Insured Lives die, you will be the beneficiary and we will pay the Benefits to you. You can also nominate two beneficiaries, if the first one is unavailable the second one will become the beneficiary.
            </Typography>
            <Box sx={{ mt: 3 }}>

                <Grid container spacing={2}>
                    <Box sx={{ mt: 3 }}>
                        {generateBeneficiaryForms()}
                    </Box>

                </Grid>


                {/* <DevTool control={control} /> */}

            </Box>
        </>
    )


    return (
        <>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {policyContent}
                    {beneficiaryContent}

                    <Box display="flex" justifyContent="center" alignItems="center" marginTop={'20px'} marginBottom={"40px"}>
                        <SharedButton disabled={!allFormCompleted} handleOnClick={handleSubmit} type={'submit'} text={`proceed`}></SharedButton>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default PolicyDetails;
