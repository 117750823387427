import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import ReactGA from "react-ga4";
import { BrowserRouter } from 'react-router-dom';

ReactGA.initialize(import.meta.env.VITE_GA_4_TAG);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
)
