import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const TrackingContext = React.createContext({
    trackEvent: ({}) => { },
});

export const TrackingContextProvider = (props) => {

    let location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
        console.log("tracking : " , location.pathname)
    }, [location]);

    const trackEvent = (category, action, label = "", value = "") => {
        // This function will send an event to Google Analytics
        ReactGA.event({
            category: category, // e.g. 'User'
            action: action, // e.g. 'Application Completed'
            label: label, // Optional, e.g. 'Fall Campaign'
            value: value, // Optional, numerical value associated with the event
        });
        console.log(`Event tracked: ${category}, ${action}, ${label}, ${value}`);
    }

    
    const contextValue = {
        trackEvent: trackEvent,
    };

    return (
        <TrackingContext.Provider value={contextValue}>
            {props.children}
        </TrackingContext.Provider>
    );
};

export default TrackingContext;