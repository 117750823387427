import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { To, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import AuthContext from '../Context/AuthContext';
import { useLoader } from '../Context/LoadingContext.jsx';
import SnackbarContext from '../Context/SnackbarContext';
import { AddressCreate_Request, Client, CustomerAddressEntity_MaritalStatuses, CustomerAddressEntity_SourcesOfFunds, IAddressCreate_Request } from '../api/insurance-api.ts';
import FormInput from '../components/Form/FormInput';
import FormSelect from '../components/Form/FormSelect';
import SharedButton from '../components/Shared/SharedButton/SharedButton';
import { addressSchema } from '../validation/schema';

const Address: React.FC = () => {

    const { customerId } = useContext(AuthContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const [showPassportNumber, setShowPassportNumber] = useState(false);
    const [loader, setLoader] = useState(false);
    const apiclient = new Client(import.meta.env.VITE_API_URL);
    const { showLoader, hideLoader } = useLoader();

    let navigate = useNavigate();

    const routeChange = (path: To) => {
        navigate(path);
    };

    type AddressInputs = z.infer<typeof addressSchema>;

    const { control, watch, reset, formState: { errors }, register, handleSubmit, setError, clearErrors, getValues } = useForm({
        mode: 'onChange',
        resolver: zodResolver(addressSchema)
    });

    const onSubmit: SubmitHandler<IAddressCreate_Request> = async (data) => {

        let addressRequest: IAddressCreate_Request = {
            city: data.city,
            postCode: data.postCode,
            province: data.province,
            streetAddress: data.streetAddress,
            suburb: data.suburb,
            unitName: data.unitName,
            unitNumber: data.unitNumber,
            maritalStatus: data.maritalStatus,
            occupation: data.occupation,
            salary: Number(data.salary),
            sourceOfFunds: data.sourceOfFunds
        }
        console.log("🚀 ~ constonSubmit:SubmitHandler<IAddressCreate_Request>= ~ addressRequest:", addressRequest)

        try {
            showLoader();

            let response = await apiclient.customerAddressCreate(customerId, new AddressCreate_Request(addressRequest))
            openSnackbar("Address saved", "success");
            routeChange("/compliance");
        } catch (error) {
            openSnackbar("Failed - please try again", "error");
        }
        finally {
            hideLoader();
        }
    };

    return (
        <>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h3'>Additional Details</Typography>
                    <Typography variant='h5'> Please enter your details</Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <FormSelect
                                    name="province"
                                    control={control}
                                    label="Province"
                                    options={[
                                        { label: "Western Cape", value: "Western Cape" },
                                        { label: "Gauteng", value: "Gauteng" },
                                        { label: "Eastern Cape", value: "Eastern Cape" },
                                        { label: "KwaZulu-Natal", value: "KwaZulu-Natal" },
                                        { label: "Free State", value: "Free State" },
                                        { label: "Limpopo", value: "Limpopo" },
                                        { label: "Mpumalanga", value: "Mpumalanga" },
                                        { label: "Northern Cape", value: "Northern Cape" },
                                        { label: "North West", value: "North West" },
                                    ]}
                                    error={Boolean(errors.province)}
                                    helperText={errors.province?.message?.toString() || "Province"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="unitNumber"
                                    control={control}
                                    label="Unit Number"
                                    error={!!errors.unitNumber}
                                    helperText={errors.unitNumber?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="unitName"
                                    control={control}
                                    label="Unit Name"
                                    error={!!errors.unitName}
                                    helperText={errors.unitName?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="streetAddress"
                                    control={control}
                                    label="Street Address"
                                    error={!!errors.streetAddress}
                                    helperText={errors.streetAddress?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="city"
                                    control={control}
                                    label="City"
                                    error={!!errors.city}
                                    helperText={errors.city?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="suburb"
                                    control={control}
                                    label="Suburb"
                                    error={!!errors.suburb}
                                    helperText={errors.suburb?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="postCode"
                                    control={control}
                                    label="Post Code"
                                    error={!!errors.postCode}
                                    helperText={errors.postCode?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormSelect
                                    name="maritalStatus"
                                    control={control}
                                    label="Marital Status"
                                    options={[
                                        { label: "Single", value: CustomerAddressEntity_MaritalStatuses.Single },
                                        { label: "Married", value: CustomerAddressEntity_MaritalStatuses.Married },
                                        { label: "Divorced", value: CustomerAddressEntity_MaritalStatuses.Divorced },
                                        { label: "Living together", value: CustomerAddressEntity_MaritalStatuses.LivingTogether },
                                    ]}
                                    error={Boolean(errors.maritalStatus)}
                                    helperText={errors.maritalStatus?.message?.toString() || "Marital Status"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="occupation"
                                    control={control}
                                    label="Occupation"
                                    error={!!errors.occupation}
                                    helperText={errors.occupation?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="salary"
                                    control={control}
                                    label="Salary"
                                    error={!!errors.salary}
                                    helperText={errors.salary?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormSelect
                                    name="sourceOfFunds"
                                    control={control}
                                    label="Source of Funds"
                                    options={[
                                        { label: "Salary", value: CustomerAddressEntity_SourcesOfFunds.Salary },
                                        { label: "Rental income", value:CustomerAddressEntity_SourcesOfFunds.RentalIncome },
                                        { label: "Investment", value: CustomerAddressEntity_SourcesOfFunds.Investment },
                                        { label: "Annuity", value: CustomerAddressEntity_SourcesOfFunds.Annuity},
                                        { label: "Other", value: CustomerAddressEntity_SourcesOfFunds.Other },
                                    ]}
                                    error={Boolean(errors.sourceOfFunds)}
                                    helperText={errors.sourceOfFunds?.message?.toString() || "Source of Funds"}
                                />
                            </Grid>

                        </Grid>
                        <Box display="flex" justifyContent="center" alignItems="center" marginTop={'20px'} marginBottom={"40px"}>
                            <SharedButton type={'submit'} text={`Proceed`}></SharedButton>
                        </Box>
                        <>
                            {Object.keys(errors).map(x => {
                                console.log("x", x);
                            })}
                        </>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Address;
