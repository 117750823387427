import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import insurance from '../../json/insurance.json';
import { CalculatePremium } from './CalculationHelper';
import { GlobalVariables, MainMemberCover, PremiumRate, PremiumRates } from './InsuranceModels';
import { InsurancePlan } from './InsuranceModels';
import Decimal from 'decimal.js';
// this should be pulled from api on 1st load 

interface InsuranceCalculatorContextProps {
  insurancePlan: InsurancePlan;
  setInsurancePlan: (insurancePlan: InsurancePlan) => void;
  calculatePremium: () => InsurancePlan;
}
interface InsuranceCalculatorProviderProps {
  children: ReactNode;
}

const defaultInsurancePlan: InsurancePlan = {
  Principal: { age: 25, coverAmount: 10000, premiumAmount: new Decimal(0) },
  Family: {
    premiumAmount: new Decimal(0),
    waivedPremiumAmount: 0,
    Spouse: { selected: false, premiumWaiver: false },
    Children: { selected: false, amount: 0 }
  },
  Parent: { selected: false, parents: [] },
  ExtendedFamily: { selected: false, extendedMembers: [] },
  totalPremiumAmount: new Decimal(0)
};

const InsuranceCalculatorContext = createContext<InsuranceCalculatorContextProps>({
  insurancePlan: defaultInsurancePlan,
  setInsurancePlan: () => { },
  calculatePremium: () => defaultInsurancePlan
});

export const InsuranceCalculatorProvider: React.FC<InsuranceCalculatorProviderProps> = ({ children }) => {

  const [insurancePlan, setInsurancePlan] = useState<InsurancePlan>(() => {
    const storedPlan = localStorage.getItem('InsurancePlan');
    return storedPlan ? JSON.parse(storedPlan) : defaultInsurancePlan;
  });

  const [premiumRates] = useState<PremiumRates>(insurance);
  const [globalVariables] = useState<GlobalVariables>(insurance.globalVariables);

  useEffect(() => {
    localStorage.setItem('InsurancePlan', JSON.stringify(insurancePlan));
  }, [insurancePlan]);

  useEffect(() => {
    if(!insurancePlan.Parent.selected){
      setInsurancePlan({
        ...insurancePlan, Parent: {
            ...insurancePlan.Parent, parents: []
        }
    })
    }
      
  }, [insurancePlan.Parent.selected])

  useEffect(() => {
    if(!insurancePlan.ExtendedFamily.selected){
      setInsurancePlan({
        ...insurancePlan, ExtendedFamily: {
            ...insurancePlan.ExtendedFamily, extendedMembers: []
        }
    })
    }
      
  }, [insurancePlan.ExtendedFamily.selected])
  

  const calculatePremium = (): InsurancePlan => {
    return CalculatePremium(insurancePlan, premiumRates, globalVariables);
  };

  return (
    <InsuranceCalculatorContext.Provider value={{ insurancePlan, setInsurancePlan, calculatePremium }}>
      {children}
    </InsuranceCalculatorContext.Provider>
  );
};

export const useInsuranceCalculator = () => useContext(InsuranceCalculatorContext);


