export const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

export const emailRegex = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export const currencyNumberWithDecimals = (x) => {
    let removeDecimals = (Math.round(parseFloat(x) * 100) / 100).toFixed(2);
    var parts = removeDecimals.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let num = parts.join(".");
    return num;
}

export const currencyNumber = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let num = parts.join(".");
    return num;
}

// export const cellphoneNumberNumericValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);