
import { useTheme } from '@mui/material/styles';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { InsurancePlan } from '../../../Context/Calculator/InsuranceModels';
import CancelCheckIcon from '../../Shared/CancelCheckIcon';
import CheckMarkIcon from '../../Shared/CheckMarkIcons';
import { currencyNumber } from "../../Shared/Regex";
import styles from './CardTile.module.scss';
import AuthContext from '../../../Context/AuthContext';
import SnackbarContext from '../../../Context/SnackbarContext';
interface CardTileProps {
    insurancePlan?: InsurancePlan
}

const CardTile: React.FC<CardTileProps> = ({ insurancePlan }) => {
    const theme = useTheme();
    let navigate = useNavigate();
    const { openSnackbar } = useContext(SnackbarContext);
    let { isLoggedIn } = useContext(AuthContext)

    let isFamilySelected = insurancePlan.Family.Spouse.selected || insurancePlan.Family.Children.selected;
    let isParentSelected = insurancePlan.Parent.selected;
    let isExtendedFamilySelected = insurancePlan.ExtendedFamily.selected;

    const handleNavigation = () => {
        if (isLoggedIn) {
            navigate('register');
        }
        else {
            openSnackbar("Please Login", "success");
            navigate('login');
        }
    }

    return (
        <>
            <div className={styles['card-tile-container']}>
                <div className={styles['card-tile-top-container']}>
                    <div className={styles['card-tile-heading']}>
                        Insurance
                    </div>
                    <div className={styles['card-tile-title']}>
                        Plan
                    </div>
                    <div className={styles['card-tile-price']}>
                        R {currencyNumber((insurancePlan?.totalPremiumAmount?.toFixed(2) ?? "0").toString())}
                    </div>
                    <div className={styles['card-tile-monthly']}>
                        per month
                    </div>
                </div>
                <div className={styles['card-tile-body-container']}>

                    <div className={styles['card-tile-content']}>
                        <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', marginBottom: 10 }}>Premium breakdown</div>
                        <div className={styles['card-tile-content-item']}>{isFamilySelected ? <>Family Plan - R  {currencyNumber((insurancePlan?.Family.premiumAmount?.toFixed(2) ?? "0").toString())}</> : <>Principal member - R {currencyNumber(insurancePlan.Principal.premiumAmount.toFixed(2).toString())}</>}</div>
                        {
                            isParentSelected &&
                            insurancePlan.Parent.parents.map((x, index) => (
                                <>
                                    <div className={styles['card-tile-content-item']}>{<>Parent {index + 1} | Age: {x.age} | R  {currencyNumber((x.premiumAmount?.toFixed(2) ?? "0").toString())}</>}</div>
                                </>
                            ))
                        }
                        {
                            isExtendedFamilySelected &&
                            insurancePlan.ExtendedFamily.extendedMembers.map((x, index) => (
                                <>
                                    <div className={styles['card-tile-content-item']}>{<>Extended Family {index + 1} | Age:{x.age} | R  {currencyNumber((x.premiumAmount?.toFixed(2) ?? "0").toString())}</>}</div>
                                </>
                            ))
                        }

                        <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}>Benefit amounts</div>
                        <div className={styles['card-tile-content-item']}>{<CheckMarkIcon />}Principal Life R{currencyNumber(insurancePlan.Principal.coverAmount)}</div>

                        <div className={styles['card-tile-content-item']}>
                            {isFamilySelected ? <CheckMarkIcon /> : <CancelCheckIcon />}
                            Spouse R{currencyNumber(Math.min(insurancePlan.Principal.coverAmount, 50000))}
                        </div>
                        <div className={styles['card-tile-content-item']}>
                            {isFamilySelected ? <CheckMarkIcon /> : <CancelCheckIcon />}
                            Children (14-21) R{currencyNumber(Math.min(insurancePlan.Principal.coverAmount, 50000))}
                        </div>
                        <div className={styles['card-tile-content-item']}>
                            {isFamilySelected ? <CheckMarkIcon /> : <CancelCheckIcon />}
                            Children (7-13) R{currencyNumber(Math.min(insurancePlan.Principal.coverAmount, 50000) * 0.5)}
                        </div>
                        <div className={styles['card-tile-content-item']}>
                            {isFamilySelected ? <CheckMarkIcon /> : <CancelCheckIcon />}
                            Age (6) R{currencyNumber(Math.min(insurancePlan.Principal.coverAmount, 50000) * 0.25)}
                        </div>
                        <div className={styles['card-tile-content-item']}>
                            {isFamilySelected ? <CheckMarkIcon /> : <CancelCheckIcon />}
                            Stillborn R{currencyNumber(Math.min(insurancePlan.Principal.coverAmount, 50000) * 0.125)}
                        </div>


                    </div>
                    <div onClick={handleNavigation} className={styles['card-tile-button']}>
                        Proceed
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardTile;
