// MainMemberComponent.tsx
import { Box, Chip, FormLabel, Input } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useInsuranceCalculator } from '../../Context/Calculator/InsuranceCalculatorContext';
import SharedSlider from '../Shared/SharedSlider/SharedSlider';
import SharedSwitch from '../Shared/SharedSwitch/SharedSwitch';
import styles from './BaseCalculator.module.scss';
// import SharedPopover from '../Shared/SharedPopover/SharedPopover';
import Decimal from 'decimal.js';
import BaseCardTile from '../Insurance/BaseCardTile/BaseCardTile';
import SharedPopover from '../Shared/SharedPopover/SharedPopover';
import { InsurancePlan } from '../../Context/Calculator/InsuranceModels';

const Calculator: React.FC = () => {
    const { insurancePlan, setInsurancePlan, calculatePremium } = useInsuranceCalculator();
    const [totalPremium, setTotalPremium] = useState<Decimal>(new Decimal(0));

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    useEffect(() => {
        const baseInsurancePlan: InsurancePlan = {
            Principal: { age: 25, coverAmount: 5000, premiumAmount: new Decimal(0) },
            Family: {
                premiumAmount: new Decimal(0),
                waivedPremiumAmount: 0,
                Spouse: { selected: false, premiumWaiver: false },
                Children: { selected: false, amount: 0 }
            },
            Parent: { selected: false, parents: [] },
            ExtendedFamily: { selected: false, extendedMembers: [] },
            totalPremiumAmount: new Decimal(25)
        };

        setInsurancePlan(baseInsurancePlan);
    }, []);


    const handleMainMemberAge = (event: any) => {
        let age = event.target.value;

        setInsurancePlan({
            ...insurancePlan, Principal: {
                ...insurancePlan.Principal, age: age
            }
        });
    }

    const handleMainMemberAgeBlur = () => {
        const currentAge = insurancePlan.Principal.age;
        const correctedAge = Math.min(120, Math.max(19, currentAge));

        setInsurancePlan({
            ...insurancePlan, Principal: {
                ...insurancePlan.Principal, age: correctedAge
            }
        });
    };

    return (
        <>
            <div className="flex flex-col lg:flex-row justify-center sm:space-x-4 my-10 mx-auto w-full">
                <div className="w-full sm:w-auto flex justify-center mb-4 sm:mb-0">
                    <div className={styles["policy-details-container"]}>
                        <div className={styles["policy-details-content"]}>
                            <div className={styles["policy-details-box-container"]}>
                                <div className={styles["policy-details-main-box"]}>
                                    <div id="demo-row-radio-buttons-group-label" className={styles["policy-details-text-container"]}>To start your process, please start by providing us with your age below and follow the next steps below. </div>
                                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: 600 }}>Main policy holder age</FormLabel>

                                    <Input
                                        onChange={handleMainMemberAge}
                                        onBlur={handleMainMemberAgeBlur}
                                        id="outlined-basic"
                                        disableUnderline={true}
                                        value={insurancePlan.Principal.age}
                                        sx={{
                                            fontSize: '40px',
                                        }}
                                    />
                                    <div className={styles["slider-container"]}>
                                        <SharedSlider
                                            sliderValue={30}
                                            handleSliderChange={handleMainMemberAge}
                                            onblur={handleMainMemberAgeBlur}
                                            min={19}
                                            max={120}
                                            step={1}
                                            value={undefined}
                                            text={"Age"}></SharedSlider>
                                    </div>
                                    <div className={styles["slider-container"]}>


                                    </div>
                                </div>
                            </div>
                            <div className={styles["policy-details-box-container"]}>
                                <div className={styles["policy-details-box"]}>
                                    <SharedSwitch toggle={(event) => {
                                        setInsurancePlan({
                                            ...insurancePlan, Family: {
                                                ...insurancePlan.Family,
                                                Spouse: {
                                                    ...insurancePlan.Family.Spouse,
                                                    selected: event.target.checked
                                                }
                                            }

                                        });

                                    }}
                                        checked={insurancePlan.Family.Spouse.selected}
                                        {...label} />
                                </div>
                                <div className={styles["policy-details-box"]}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Would you like to add your spouse to your policy<SharedPopover type={"spouse"}></SharedPopover></FormLabel>
                                </div>
                            </div>
                            <div className={styles["policy-details-box-container"]}>
                                <div className={styles["policy-details-box"]}>
                                    <SharedSwitch toggle={(event) => {
                                        setInsurancePlan({
                                            ...insurancePlan, Family: {
                                                ...insurancePlan.Family,
                                                Children: {
                                                    ...insurancePlan.Family.Children,
                                                    selected: event.target.checked
                                                }
                                            }
                                        });
                                    }}
                                        checked={insurancePlan.Family.Children.selected}
                                        {...label} />
                                </div>
                                <div className={styles["policy-details-box"]}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Would you like to add your children to your policy<SharedPopover type={"children"}></SharedPopover></FormLabel>
                                </div>

                            </div>
                            <div className={styles["policy-details-slider-box-container"]}>
                                {insurancePlan.Family.Children.selected &&
                                    <>

                                        {/* <Chip sx={{backgroundColor: '#71bee5', color: 'white'}} */}
                                        <Chip className="min-w-[150px]"
                                            label={`${insurancePlan.Family.Children.amount} ${insurancePlan.Family.Children.amount > 1 ? "Children" : "Child"}` + " Selected"}
                                        />

                                        <Box sx={{ width: 300 }}>
                                            <div className={styles["policy-details-slider-box"]}>
                                                <div className={styles["slider-container-small"]}>
                                                    <SharedSlider
                                                        step={1}
                                                        sliderValue={insurancePlan.Family.Children.amount}
                                                        handleSliderChange={(event) => {
                                                            setInsurancePlan({
                                                                ...insurancePlan, Family: {
                                                                    ...insurancePlan.Family,
                                                                    Children: {
                                                                        ...insurancePlan.Family.Children,
                                                                        amount: event.target.value
                                                                    }
                                                                }
                                                            })
                                                        }

                                                        }
                                                        onblur={() => { }}

                                                        value={insurancePlan.Family.Children.amount}
                                                        min={1}
                                                        max={5}
                                                        text={"children"}
                                                    />
                                                </div>
                                            </div>
                                        </Box>
                                    </>
                                }
                            </div>


                        </div>
                    </div >
                </div>
                <div className="sm:w-auto mb-4 sm:mb-0">
                    <div className="sm:w-auto sm:h-full ">
                        <BaseCardTile />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Calculator;
