export class AuthorizedApiBase {
    protected authToken: string | null;

    protected constructor() {
        this.authToken = localStorage.getItem("token"); 
    }

    initializeAuthToken(): void {
        this.authToken = localStorage.getItem("token");
    }

    setAuthToken(token: string): void {
        this.authToken = token;
    }

    protected async transformOptions(options: RequestInit): Promise<RequestInit> {
        if (!this.authToken) {
            console.warn("Authorization token is not set.");
        }

        const headers = options.headers instanceof Headers ? options.headers : new Headers(options.headers);
        headers.append('Authorization', `Bearer ${this.authToken}`);

        return { ...options, headers };
    }
}
