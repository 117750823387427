import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { To, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import AuthContext from '../Context/AuthContext';
import { useInsuranceCalculator } from '../Context/Calculator/InsuranceCalculatorContext.tsx';
import { useLoader } from '../Context/LoadingContext.jsx';
import SnackbarContext from '../Context/SnackbarContext';
import { Client, Create_Request, Create_Request_ForeignNationalDto, Gender, ICreate_Request, ICreate_Request_ForeignNationalDto } from '../api/insurance-api.ts';
import FormInput from '../components/Form/FormInput';
import FormSelect from '../components/Form/FormSelect';
import SharedButton from '../components/Shared/SharedButton/SharedButton';
import SharedSwitch from '../components/Shared/SharedSwitch/SharedSwitch';
import { getDynamicRegisterSchema } from '../validation/schema';

const Register: React.FC = () => {

    const { isLoggedIn, registerCustomer } = useContext(AuthContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const { insurancePlan } = useInsuranceCalculator();
    const { showLoader, hideLoader } = useLoader();
    const [showPassportNumber, setShowPassportNumber] = useState(false);
    const [loader, setLoader] = useState(false);
    const apiclient = new Client(import.meta.env.VITE_API_URL);

    let navigate = useNavigate();

    const routeChange = (path: To) => {
        navigate(path);
    };

    useEffect(() => {


    }, [])

    const currentSchema = getDynamicRegisterSchema(showPassportNumber);

    type RegisterInputs = z.infer<typeof currentSchema>;

    const { control, watch, reset, formState: { errors }, register, setValue, handleSubmit, setError, clearErrors, getValues } = useForm({
        mode: 'onChange',
        resolver: zodResolver(currentSchema)
    });

    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const strippedInput = value.replace(/[^0-9]/g, "");

        let formattedDate = strippedInput;

        if (strippedInput.length > 4) {
            formattedDate = `${strippedInput.slice(0, 4)}-${strippedInput.slice(4)}`;
        }
        if (strippedInput.length > 6) {
            formattedDate = `${formattedDate.slice(0, 7)}-${formattedDate.slice(7)}`;
        }

        formattedDate = formattedDate.slice(0, 10);
        setValue(name, formattedDate);
    };

    const onSubmit: SubmitHandler<ICreate_Request> = async (data: RegisterInputs) => {

        let registerRequest: ICreate_Request = {
            title: data.title,
            firstName: data.firstName,
            middleName: data.middleName && data.middleName.trim() === '' ? undefined : data.middleName && data.middleName.trim(),
            lastName: data.lastName,
            cellNumber: data.cellnumber,
            email: data.email,
            idNumber: showPassportNumber ? undefined : data.idNumber,
        };

        if (showPassportNumber) {
            let foreignNationalDto: ICreate_Request_ForeignNationalDto = {
                passportNumber: data.passportNumber,
                nationality: data.nationality,
                permitType: data.permitType,
                birthDate: new Date(data.birthDate),
                gender: data.gender,
            };

            registerRequest.foreignNational = new Create_Request_ForeignNationalDto(foreignNationalDto);
        }
        else {
            registerRequest.foreignNational = undefined;
            registerRequest.idNumber = data.idNumber
        }

        try {
            showLoader();
            const response = await apiclient.customerCreate(new Create_Request(registerRequest));
            registerCustomer(response.id);
            openSnackbar("Customer registration successful", "success");
            routeChange("/address");
        } catch (error) {
            console.log("failed ", error);
            openSnackbar("Registration Failed - please try again", "error");
        }
        finally {
            hideLoader();
        }
    };

    return (
        <>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Typography variant='h3' sx={{ color: '#777774' }}>Policy Details</Typography>
                    <Typography variant='h5'> Please enter your details</Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormSelect
                                    name="title"
                                    control={control}
                                    label="Title"
                                    options={[
                                        { label: "Mr.", value: "Mr." },
                                        { label: "Mrs.", value: "Mrs." },
                                        { label: "Miss", value: "Miss" },
                                        { label: "Dr.", value: "Dr." },
                                        { label: "Prof.", value: "Prof." },
                                        { label: "Capt.", value: "Capt." },
                                        { label: "Sgt.", value: "Sgt." },
                                        { label: "Sir", value: "Sir" },
                                    ]}
                                    error={Boolean(errors.title)}
                                    helperText={errors.title?.message?.toString() || "Title"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="firstName"
                                    control={control}
                                    label="Full Name"
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="middlename"
                                    control={control}
                                    label="Middle Name"
                                    error={!!errors.middlename}
                                    helperText={errors.middlename?.message?.toString()}
                                    fullWidth

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="lastName"
                                    control={control}
                                    label="Last Name"
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} container alignItems="center">
                                <Grid xs={2}>
                                    <SharedSwitch toggle={() => { setShowPassportNumber(!showPassportNumber); }} checked={showPassportNumber}></SharedSwitch>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body1">I want to use my passport number instead</Typography>
                                </Grid>
                            </Grid>


                            {
                                !showPassportNumber &&

                                <Grid item xs={12}>
                                    <FormInput
                                        name="idNumber"
                                        control={control}
                                        label="ID Number"
                                        error={!!errors.idNumber}
                                        helperText={errors.idNumber?.message?.toString() || ""}
                                        fullWidth
                                        required
                                        autoComplete="off"
                                    />
                                </Grid>
                            }
                            {
                                showPassportNumber &&
                                <>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name="passportNumber"
                                            control={control}
                                            label="passportNumber"
                                            error={!!errors.passportNumber}
                                            helperText={errors.passportNumber?.message?.toString()}
                                            fullWidth
                                            required
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name="nationality"
                                            control={control}
                                            label="Nationality"
                                            error={!!errors.nationality}
                                            helperText={errors.nationality?.message?.toString()}
                                            fullWidth
                                            required
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormSelect
                                            name="permitType"
                                            control={control}
                                            label="Permit Type"
                                            options={[
                                                { label: "Work Visa", value: "Work Visa" },
                                                { label: "Study Visa", value: "Study Visa" },
                                            ]}
                                            error={Boolean(errors.permitType)}
                                            helperText={errors.permitType?.message?.toString() || "Title"}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name="birthDate"
                                            control={control}
                                            label="Birth Date"
                                            error={!!errors.birthDate}
                                            helperText={errors.birthDate?.message?.toString()}
                                            fullWidth
                                            required
                                            autoComplete="off"
                                            onChange={(e) => {
                                                handleDateChange(e);
                                                // field.onChange(e); // Ensure React Hook Form registers the change
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormSelect
                                            name="gender"
                                            control={control}
                                            label="Gender"
                                            options={[
                                                { label: "Male", value: Gender.Male },
                                                { label: "Female", value: Gender.Female },
                                            ]}
                                            error={Boolean(errors.gender)}
                                            helperText={errors.gender?.message?.toString() || "Gender"}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <FormInput
                                    name="cellnumber"
                                    control={control}
                                    label="Cell Number"
                                    error={!!errors.cellnumber}
                                    helperText={errors.cellnumber?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="email"
                                    control={control}
                                    label="Email Address"
                                    error={!!errors.email}
                                    helperText={errors.email?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="center" alignItems="center" marginTop={'20px'} marginBottom={"40px"}>
                            <SharedButton type={'submit'} text={`Register`}></SharedButton>
                        </Box>
                        {/* <DevTool control={control} /> */}
                        <>
                            {Object.keys(errors).map(x => {
                                console.log("x", x);
                            })}
                        </>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Register;
