import { zodResolver } from '@hookform/resolvers/zod';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Container, CssBaseline, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { To, useNavigate } from 'react-router-dom';
import AuthContext from '../Context/AuthContext';
import { useLoader } from '../Context/LoadingContext.jsx';
import SnackbarContext from '../Context/SnackbarContext';
import { Agents_LoginRequest, Client, IAgents_LoginRequest } from '../api/insurance-api.ts';
import FormInput from '../components/Form/FormInput';
import SharedButton from '../components/Shared/SharedButton/SharedButton';
import { loginSchema } from '../validation/schema';

const Login: React.FC = () => {

    const { login } = useContext(AuthContext);
    const { showLoader, hideLoader } = useLoader();
    const { openSnackbar } = useContext(SnackbarContext);
    const apiclient = new Client(import.meta.env.VITE_API_URL);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    let navigate = useNavigate();

    const routeChange = (path: To) => {
        navigate(path);
    };

    const { control, watch, reset, formState: { errors }, register, handleSubmit, setError, clearErrors, getValues } = useForm({
        mode: 'onChange',
        resolver: zodResolver(loginSchema)
    });

    const onSubmit: SubmitHandler<IAgents_LoginRequest> = async (data) => {

        let loginRequest: IAgents_LoginRequest = {
            email: data.email,
            password: data.password,
        };

        try {
            showLoader();
            let response = await apiclient.agentLogin(new Agents_LoginRequest(loginRequest))
            login(response.token);
            openSnackbar("Login Successful", "success");
            routeChange("/");
        } catch (error) {
            openSnackbar("Login Failed - please try again", "error");
        }
        finally {
            hideLoader();
        }
    };

    return (
        <>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h3' sx={{ color: '#777774' }}>Login</Typography>
                    <Typography variant='h5'> Please enter your login details</Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInput
                                    name="email"
                                    control={control}
                                    label="Email Address"
                                    error={!!errors.email}
                                    helperText={errors.email?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="password"
                                    control={control}
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    error={!!errors.password}
                                    helperText={errors.password?.message?.toString()}
                                    fullWidth
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="center" alignItems="center" marginTop={'20px'} marginBottom={"40px"}>
                            <SharedButton type={'submit'} text={`Login`}></SharedButton>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Login;
