import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  colors: {
    primary: "#F05454",
    primaryLight: "#c9a7be",
    primaryExtraLight: "#9e8b98",
    secondary: "#F05454",
    secondaryLight: "#ed8c8c",
    secondaryHover: "#ed8c8c",
    textColor: "#ad4c18",
  },
  palette: {
    mode: "light",
    primary: {
      main: "#F05454", // primary ma
      light: "#ed8c8c", // hover
      extraLight: "#9e8b98", // custom shade, not a standard palette property
    },
    secondary: {
      main: "#F05454",
      light: "#ed8c8c", // secondary light color
      hover: "#ed8c8c", // custom shade, not a standard palette property
    },
    text: {
      primary: "#000000", // primary text color
      // You can also add 'secondary', 'disabled', etc.
    }
  },
  // Custom properties can still be defined outside the palette
  styling: {
    borderRadius: "10px"
  },
  tiles: {
    margin: "10px",
    color: "#ed8c8c",
    colorLight: "#50a0c7", 
    bulletSize: 10,
    unused: '#9E9E9E'
  },
  accordion: {
    titleColor: "#F05454",
    bodyColor: "white"
  },
  slider: {
    track: {
      radius: '3px',
      height: '10px',
    },
    thumb: {
      height: '40px',
      width: "40px",
      backgroundColor: "#f1f0f0",
      textColor: '#000000'
    },
    rail: {
      radius: '3px',
      height: '10px',
      backgroundColor: "rgb(205 205 205)",
    }
  }
});

export default theme;
