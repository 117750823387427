import React, { createContext, useState, useCallback, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Create a Context for the snackbar
const SnackbarContext = React.createContext({
  openSnackbar: (message, severity) => { },
  closeSnackbar: () => { }
});

// Create a component that provides the snackbar context
export const SnackbarProvider = (props) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const openSnackbar = (message, severity = 'info') => {
    setSnackbarState({ open: true, message, severity });
  };

  const closeSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const contextValues = {
    openSnackbar: openSnackbar,
    closeSnackbar: closeSnackbar
  }

  return (
    <SnackbarContext.Provider value={contextValues}>
      <>
        {props.children}
      </>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={4500}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={closeSnackbar} severity={snackbarState.severity} elevation={6} variant="filled">
          {snackbarState.message}
        </MuiAlert>
      </Snackbar>
    </SnackbarContext.Provider>

  );
};

export default SnackbarContext;
