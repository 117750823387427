import Button from '@mui/material/Button';

const SharedButton = ({ text, handleOnClick, disabled }) => {

    return (
        <>
            <Button
                disabled={disabled}
                onClick={handleOnClick}
                variant="contained"
            >{text}</Button>

        </>
    )
}

export default SharedButton