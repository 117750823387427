import { z } from 'zod';
import { emailRegex, passwordRegex } from '../components/Shared/Regex/regex';
import nationalIdNumber from '../components/Shared/IdNumberValidator';
import { Gender, CustomerBankingDetailEntity_DebitOrderDays, CustomerAddressEntity_MaritalStatuses,CustomerDependantEntity_DependantType, CustomerAddressEntity_SourcesOfFunds } from '../api/insurance-api';

export const loginSchema = z.object({
    email: z.string().regex(emailRegex, { message: "Invalid Email address" }).min(1, { message: "Name is required" }),
    password: z.string().min(8, { message: "Password must be atleast 8 characters long" }) // for testing 
    // password: z.string().regex(passwordRegex, { message: "Your password must be atleast 8 characters long and contain atleast 1 uppercase letter, 1 number and 1 special character" }).min(8, { message: "Password must be atleast 8 characters long" })
});

export const baseRegisterSchema = z.object({
    title: z.string(),
    firstName: z.string(),
    middleName: z.string().optional(),
    lastName: z.string(),
    email: z.string().regex(emailRegex, { message: "Invalid Email address" }).min(1, { message: "Email is required" }),
    cellnumber: z.string().min(10, { message: "Invalid cell number" }),
    championId: z.string().optional(),
});

export const getDynamicRegisterSchema = (showPassport) => {
    if (showPassport) {
        return baseRegisterSchema.extend({
            idNumber: z.string().optional(),
            passportNumber: z.string(),
            nationality: z.string().min(1, { message: "Nationality is required" }),
            permitType: z.string().min(1, { message: "Permit Type is required" }),
            gender: z.nativeEnum(Gender),
            birthDate: z.string(),
        });
    } else {
        return baseRegisterSchema.extend({
            idNumber: z.string()
                .min(1, { message: "ID Number is required" })
                .refine(validateIdNumberMainMember, { message: "Invalid ID Number or below age requirement" }),
            passportNumber: z.string().optional(),
            nationality: z.string().optional(),
            permitType: z.string().optional(),
            gender: z.nativeEnum(Gender).optional(),
            birthDate: z.string().optional()
        });
    }
};

export const addressSchema = z.object({
    province: z.string(),
    unitNumber: z.union([
        z.string()
            .regex(/^\d{1,8}$/, "number ex. 56")
            .or(z.literal("")),
        z.undefined(),
    ]).optional(),
    unitName: z.string().optional(),
    streetAddress: z.string(),
    city: z.string(),
    suburb: z.string(),
    postCode: z.string().regex(/^\d{1,4}$/, "PostCode must be 1 to 4 digits long"), // Regex to ensure the string is only composed of 1 to 4 digits,
    maritalStatus: z.nativeEnum(CustomerAddressEntity_MaritalStatuses),
    occupation: z.string(),
    salary: z.string(),
    sourceOfFunds: z.nativeEnum(CustomerAddressEntity_SourcesOfFunds),
});

export const complianceSchema = z.object({
    alternatePolicy: z.boolean(),
    cancelAlternatePolicy: z.boolean().optional(),
    waitingPeriodExpired: z.boolean().optional(),

    alternatePolicyNumber: z.string().optional(),
    alternatePolicyName: z.string().optional(),
    alternatePolicyPaidUp: z.boolean().optional(),
    alternatePolicyBenefitsSame: z.boolean().optional(),

    politicallyExposedPerson: z.boolean(),
    politicallyExposedPersonReason: z.string().optional(),

    domesticProminentInfluential: z.boolean(),
    domesticProminentInfluentialReason: z.string().optional(),

    foreignProminentPublicOfficial: z.boolean(),
    foreignProminentPublicOfficialReason: z.string().optional(),

    knownCloseAssociate: z.boolean(),
    knownCloseAssociateReason: z.string().optional(),
}).superRefine((data, ctx) => {
    if (data.waitingPeriodExpired) {
        if (!data.alternatePolicyNumber) {
            ctx.addIssue({
                path: ['alternatePolicyNumber'],
                message: 'Alternate policy number is required.',
                code: z.ZodIssueCode.custom,
            });
        }
        if (!data.alternatePolicyName) {
            ctx.addIssue({
                path: ['alternatePolicyName'],
                message: 'Alternate policy name is required.',
                code: z.ZodIssueCode.custom,
            });
        }
        // if (!data.alternatePolicyBenefitsSame) {
        //     ctx.addIssue({
        //         path: ['alternatePolicyBenefitsSame'],
        //         message: 'Alternate policy benefits same is required.',
        //         code: z.ZodIssueCode.custom,
        //     });
        // }
    }

    if (data.politicallyExposedPerson && !data.politicallyExposedPersonReason) {
        ctx.addIssue({
            path: ['politicallyExposedPersonReason'],
            message: 'Politically exposed person reason is required.',
            code: z.ZodIssueCode.custom,
        });
    }

    if (data.domesticProminentInfluential && !data.domesticProminentInfluentialReason) {
        ctx.addIssue({
            path: ['domesticProminentInfluentialReason'],
            message: 'Domestic prominent influential reason is required.',
            code: z.ZodIssueCode.custom,
        });
    }

    if (data.foreignProminentPublicOfficial && !data.foreignProminentPublicOfficialReason) {
        ctx.addIssue({
            path: ['foreignProminentPublicOfficialReason'],
            message: 'Foreign prominent public official reason is required.',
            code: z.ZodIssueCode.custom,
        });
    }

    if (data.knownCloseAssociate && !data.knownCloseAssociateReason) {
        ctx.addIssue({
            path: ['knownCloseAssociateReason'],
            message: 'Known close associate reason is required.',
            code: z.ZodIssueCode.custom,
        });
    }
});


// export const policySchema = z.object({
//     // this will be based on the dependents the customer selects
// });

// export const paymentMethodSchema = z.object({
//     // this will be based on the dependents the customer selects
// });

export const bankingSchema = z.object({
    premiumStartDate: z.string(),
    accountHolderName: z.string().min(1),
    accountType: z.string().min(1, { message: "ex. Cheque" }),
    accountNumber: z.string().regex(/^\d+$/, { message: "Account Number must be numeric." }),
    branchCode: z.string().regex(/^\d+$/, { message: "Branch Code must be numeric." }),
    bankName: z.string(),
    debitOrderDay: z.nativeEnum(CustomerBankingDetailEntity_DebitOrderDays),
});


function validateIdNumberMainMember(idNumber) {
    const trimmedIdNumber = idNumber.replace(/\s/g, '');
    return nationalIdNumber({ number: trimmedIdNumber, minAge: 18 });
}

function validateIdNumberNoAgeLimit(idNumber) {
    const trimmedIdNumber = idNumber.replace(/\s/g, '');
    return nationalIdNumber({ number: trimmedIdNumber, minAge: 0 }); // remeber this is for depedents like children, ect
}
// dependent

export const baseDependentSchema = z.object({
    firstName: z.string(),
    middleName: z.string().optional(),
    lastName: z.string()
});

export const getDynamicDependentSchema = (showPassport, type) => {
    if (showPassport) {
        return baseDependentSchema.extend({
            idNumber: z.string().optional(),
            passportNumber: z.string(),
            nationality: z.string().min(1, { message: "Nationality is required" }),
            permitType: z.string().min(1, { message: "Permit Type is required" }),
            gender: z.nativeEnum(Gender),
            birthDate: z.string(),
            relationship: type == CustomerDependantEntity_DependantType.ExtendedFamily ? z.string() : z.string().optional()
        });
    } else {
        return baseDependentSchema.extend({
            idNumber: z.string()
                .min(1, { message: "ID Number is required" })
                .refine(validateIdNumberNoAgeLimit, { message: "Invalid ID Number or below age requirement" }),
            passportNumber: z.string().optional(),
            nationality: z.string().optional(),
            permitType: z.string().optional(),
            gender: z.nativeEnum(Gender).optional(),
            birthDate: z.string().optional(),
            relationship: type == CustomerDependantEntity_DependantType.ExtendedFamily ? z.string() : z.string().optional()

        });
    }
};

export const baseBeneficiarySchema = z.object({
    firstName: z.string(),
    middleName: z.string().optional(),
    lastName: z.string(),
    relationship: z.string(),
    cellNumber: z.string().min(10, { message: "Invalid cell number" }),
    relationship: z.string()
});

export const getDynamicBeneficiarySchema = (showPassport) => {
    if (showPassport) {
        return baseBeneficiarySchema.extend({
            idNumber: z.string().optional(),
            passportNumber: z.string(),
            nationality: z.string().min(1, { message: "Nationality is required" }),
            permitType: z.string().min(1, { message: "Permit Type is required" }),
            gender: z.nativeEnum(Gender),
            birthDate: z.string(),
        });
    } else {
        return baseBeneficiarySchema.extend({
            idNumber: z.string()
                .min(1, { message: "ID Number is required" })
                .refine(validateIdNumberNoAgeLimit, { message: "Invalid ID Number or below age requirement" }),
            passportNumber: z.string().optional(),
            nationality: z.string().optional(),
            permitType: z.string().optional(),
            gender: z.nativeEnum(Gender).optional(),
            birthDate: z.string().optional()
        });
    }
};

export const premiumSchema = z.object({
    premiumAccepted: z.boolean(),
    reference: z.string().optional()
});