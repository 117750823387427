import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';

const CheckMarkIcon = () => {

    const theme = useTheme();

    // 
    // const styles = [
    //     { color: '#C3B1E1' },
    //     { color: '#A0DDFF' },
    //     { color: '#C7E3A4' }
    // ];

    return (
        <CheckCircleIcon margin sx={{color: theme.palette.primary.light, marginRight: '8px' }}/>
    )
}

export default CheckMarkIcon