import { styled } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popover from '@mui/material/Popover';
import React from 'react';
import theme from '../theme';

const SharedPopover = ({ type }) => {

    const types = [
        { type: 'spouse', message: "Spouse (max entry age 65)" },
        { type: 'waiver', message: "All premiums for the spouse and all the children will not be payable for a period of one year." },
        { type: 'children', message: "Maximum of 5 children can be added to the policy (up to age 21)" },
        { type: 'parent', message: "Maximum of 4 parents can be added to the policy (up to age 75)" },
        { type: 'extended', message: "Maximum of 5 children can be added to the policy (up to age 65)" },
        { type: 'additional', message: "Additional R 65 000 benefit for the principal member" },
        { type: 'politicallyExposedPerson', message: "A Politically exposed person is someone who is or has been entrusted with prominent public functions, based on a specific political affiliation. Examples: A head of state, cabinet minister, member of parliament/local/provincial government, senior administrator in government department (financial department/tender processes), senior judge, manager of local municipalities who award tenders, senior and/or influential official, ambassador/high commissioner, senior representative of a religious organisation, leader of a political party, accounting officer of a national or provincial department, municipal manager, officer of SANDF." },
        { type: 'domesticProminentInfluential', message: "A Prominent influential person is an individual who holds, or has at any time in the preceding 12 months, the position of chairperson of board of directors, chairperson of the audit committee, executive officer, or chief financial officer of a company as defined in the companies Act 71 of 2008, if the company provides goods or services to an organ of state." },
        { type: 'foreignProminentPublicOfficial', message: "Foreign political exposed person refers to who holds or has held in any foreign country a prominent public function including that of a head of state, member of a foreign royal family, government minister or leader of a political party, senior judicial official, senior executive of a state-owned corporation, or high ranking member of the military." },
        { type: 'knownCloseAssociate', message: "A known close associate is an individual who is closely connected to a prominent person, either socially or professionally. The term “close associate” is not intended to capture every person who has been associated with a prominent person. Examples: Known relationships outside the family unit (e.g. girlfriends, boyfriends, mistresses), a prominent member of the same political party, civil organisation, labour or employee union as the prominent person, business partner or associate, especially one who shares (beneficial) ownership of corporate vehicles with the prominent person, or who is otherwise connected (e.g. through joint membership of a company board), any individual who has sole beneficial ownership of a corporate vehicle set up for the actual benefit of the prominent person." },
    ]

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isMobileMatch = useMediaQuery("(max-width:600px)");

    // const PopoverBox = styled(Popover)({
    //     '& .MuiPopover-paper': {
    //         backgroundColor: '#c9a7be',
    //         color: theme.tiles.text,
    //         padding: '0.5rem',
    //         fontSize: '0.8rem',
    //         fontweight: '600',
    //         fontFamily: 'Times New Roman, sans-serif',
    //         borderRadius: '0.5rem',
    //         boxShadow: '0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2)',
    //         border: '2px solid #4F4557',
    //         maxWidth: '20rem',
    //         textAlign: 'center',
    //         // [theme.breakpoints.down('sm')]: {
    //         //     width: '100%',
    //         //   },
    //     },
    // });

    // const useStyles = styled((theme) => ({
    //     icon: {
    //         display: 'inline-block',
    //         verticalAlign: 'right',
    //         marginRight: theme.spacing(1),
    //     },
    // }));

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
        <>
            <HelpOutlineIcon sx={{ color: theme.tiles.color, fontSize: 22, marginLeft: 0.5, position: "absolute" }} onClick={handleClick}></HelpOutlineIcon>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >{types?.find((t) => t.type === type)?.message || 'No type found'}</Popover>
        </>
    )
}

export default SharedPopover