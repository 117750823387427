import { Box, Chip, FormLabel, Input, Stack, TextField } from '@mui/material';
import Decimal from 'decimal.js';
import React, { useEffect, useState } from 'react';
import { useInsuranceCalculator } from '../../Context/Calculator/InsuranceCalculatorContext';
import { InsurancePlan } from '../../Context/Calculator/InsuranceModels';
import CardTile from '../Insurance/CardTile/CardTile';
import SharedAddButton from '../Shared/SharedAddButton/SharedAddButton';
import SharedPopover from '../Shared/SharedPopover/SharedPopover';
import SharedSlider from '../Shared/SharedSlider/SharedSlider';
import SharedSwitch from '../Shared/SharedSwitch/SharedSwitch';
import styles from './Calculator.module.scss';

const Calculator: React.FC = () => {
    const { insurancePlan, setInsurancePlan, calculatePremium } = useInsuranceCalculator();
    const [totalPremium, setTotalPremium] = useState<Decimal>(new Decimal(0));
    const [ageOfParentsCollection, setAgeOfParentsCollection] = useState([]);
    const [ageOfExtendedFamilyCollection, setAgeOfExtendedFamilyCollection] = useState([]);

    const [parentAge, setParentAge] = useState<number>(45);
    const [parentCoverAmount, setParentCoverAmount] = useState<number>(10000);

    const [extendedFamilyAge, setExtendedFamilyAge] = useState<number>(45);
    const [extendedFamilyCoverAmount, setExtendedFamilyCoverAmount] = useState<number>(10000);

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const handleMainMemberAge = (event: any) => {
        let age = event.target.value ? Number(event.target.value) : 0;

        setInsurancePlan({
            ...insurancePlan, Principal: {
                ...insurancePlan.Principal, age: age
            }
        });
    }

    const handleMainMemberAgeBlur = () => {
        let { age } = insurancePlan.Principal;
        age = Number(age) || 0;

        if (age > 65) {
            age = 65;
        } else if (age < 19) {
            age = 19;
        }


        setInsurancePlan({
            ...insurancePlan, Principal: {
                ...insurancePlan.Principal, age: age
            }
        });
    };

    const handleMainMemberCover = (event: any) => {
        let cover = event.target.value;
        const correctedCoverAmount = Math.max(5000, Math.min(100000, cover));

        setInsurancePlan({
            ...insurancePlan, Principal: {
                ...insurancePlan.Principal, coverAmount: correctedCoverAmount
            }
        });
    
    }

    useEffect(() => {
        let parentCoveramount = Number(parentCoverAmount) || 0;
        let extendedFamilyCovermount = Number(extendedFamilyCoverAmount) || 0;

        if (parentCoveramount > insurancePlan.Principal.coverAmount) {

            parentCoveramount = insurancePlan.Principal.coverAmount
        }

        if(extendedFamilyCovermount > insurancePlan.Principal.coverAmount){
            extendedFamilyCovermount = insurancePlan.Principal.coverAmount
        }

        if (parentCoveramount > 50000) {
            parentCoveramount = 50000;
        }

        if (extendedFamilyCovermount > 50000) {
            extendedFamilyCovermount = 50000;
        }

        setParentCoverAmount(parentCoveramount);
        setExtendedFamilyCoverAmount(extendedFamilyCovermount);
    }, [insurancePlan.Principal.premiumAmount])    

    const handleMainMemberCoverBlur = () => {
        const currentCoverAmount = insurancePlan.Principal.coverAmount;
        const correctedCoverAmount = Math.max(5000, Math.min(100000, currentCoverAmount));

        setInsurancePlan({
            ...insurancePlan, Principal: {
                ...insurancePlan.Principal, coverAmount: correctedCoverAmount
            }
        });
    }

    const addParentToChip = () => {
        if (insurancePlan.Parent.parents.length < 4) {
            console.log("parentAge : ", parentAge);
            const updatedPlan = { ...insurancePlan };
            updatedPlan.Parent = {
                ...updatedPlan.Parent,
                parents: [
                    ...updatedPlan.Parent.parents,
                    { id: Math.random().toString(36).substring(2, 9), age: parentAge, coverAmount: parentCoverAmount }
                ]
            };

            setInsurancePlan(updatedPlan);
        }

        else {
            console.log("Maximum of 4 parents allowed to add");
        }
    }

    const removeParentChip = (parentIdToDelete) => {
        const newParents = insurancePlan.Parent.parents.filter(parent => parent.id !== parentIdToDelete.id);
        const updatedPlan: InsurancePlan = {
            ...insurancePlan,
            Parent: {
                ...insurancePlan.Parent,
                parents: newParents
            }
        };

        setInsurancePlan(updatedPlan);
    };

    const addExtendedFamilyToChip = () => {
        if (insurancePlan.ExtendedFamily.extendedMembers.length < 4) {
            const updatedPlan = { ...insurancePlan };
            updatedPlan.ExtendedFamily = {
                ...updatedPlan.ExtendedFamily,
                extendedMembers: [
                    ...updatedPlan.ExtendedFamily.extendedMembers,
                    { id: Math.random().toString(36).substring(2, 9), age: extendedFamilyAge, coverAmount: extendedFamilyCoverAmount }
                ]
            };

            setInsurancePlan(updatedPlan);
        }

        else {
            console.log("Maximum of 4 extended family members allowed");
        }
    }

    const removeExtendedFamilyChip = (extendedFamilyIdToDelete) => {

        const newExtendedFamily = insurancePlan.ExtendedFamily.extendedMembers.filter(extendedFamily => extendedFamily.id !== extendedFamilyIdToDelete.id);
        const updatedPlan: InsurancePlan = {
            ...insurancePlan,
            ExtendedFamily: {
                ...insurancePlan.ExtendedFamily,
                extendedMembers: newExtendedFamily
            }
        };

        setInsurancePlan(updatedPlan);
    };

    return (
        <>
            <div className={styles["calculator-container"]}>
                <div className={styles["item-container"]}>
                    <div className={styles["policy-details-container"]}>
                        <div className={styles["policy-details-content"]}>
                            <div className={styles["policy-details-box-container"]}>
                                <div className={styles["policy-details-main-box"]}>
                                    <div id="demo-row-radio-buttons-group-label" className={styles["policy-details-text-container"]}>To start your process, please start by providing us with your age below and follow the next steps below. </div>
                                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: 600 }}>Main policy holder age</FormLabel>

                                    <Input
                                        onChange={handleMainMemberAge}
                                        onBlur={handleMainMemberAgeBlur}
                                        id="outlined-basic"
                                        disableUnderline={true}
                                        value={insurancePlan.Principal.age}
                                        sx={{
                                            fontSize: '40px',
                                        }}
                                    />
                                    <div className={styles["slider-container"]}>
                                        <SharedSlider
                                            sliderValue={30}
                                            handleSliderChange={handleMainMemberAge}
                                            onblur={handleMainMemberCoverBlur}
                                            min={19}
                                            max={65}
                                            step={1}
                                            value={undefined}
                                            text={"Age"}></SharedSlider>
                                    </div>
                                    <div className={styles["slider-container"]}>
                                    </div>

                                    {/* <div id="demo-row-radio-buttons-group-label" className={styles["policy-details-text-container"]}>To start your process, please start by providing us with your age below and follow the next steps below. */}
                                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: 600 }}>Main policy cover</FormLabel>
                                    <Input
                                        onChange={handleMainMemberCover}
                                        id="outlined-basic"
                                        disableUnderline={true}
                                        value={"R " + insurancePlan.Principal.coverAmount}
                                        sx={{
                                            fontSize: '40px',
                                        }}
                                    />
                                    <div className={styles["slider-container"]}>
                                        <SharedSlider
                                            sliderValue={10000}
                                            handleSliderChange={handleMainMemberCover}
                                            onblur={() => { }}
                                            min={5000}
                                            max={80000}
                                            step={500}
                                            value={insurancePlan.Principal.coverAmount}
                                            text={"Cover"}></SharedSlider>
                                    </div>
                                    {/* </div> */}

                                </div>
                            </div>
                            <div className={styles["policy-details-box-container"]}>
                                <div className={styles["policy-details-box"]}>
                                    <SharedSwitch toggle={(event) => {
                                        setInsurancePlan({
                                            ...insurancePlan, Family: {
                                                ...insurancePlan.Family,
                                                Spouse: {
                                                    ...insurancePlan.Family.Spouse,
                                                    selected: event.target.checked
                                                }
                                            }

                                        });

                                    }}
                                        checked={insurancePlan.Family.Spouse.selected}
                                        {...label} />
                                </div>
                                <div className={styles["policy-details-box"]}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Would you like to add your spouse to your policy<SharedPopover type={"spouse"}></SharedPopover></FormLabel>
                                </div>
                            </div>
                            <div className={styles["policy-details-box-container"]}>
                                <div className={styles["policy-details-box"]}>
                                    <SharedSwitch toggle={(event) => {
                                        setInsurancePlan({
                                            ...insurancePlan, Family: {
                                                ...insurancePlan.Family,
                                                Children: {
                                                    ...insurancePlan.Family.Children,
                                                    selected: event.target.checked
                                                }
                                            }
                                        });
                                    }}
                                        checked={insurancePlan.Family.Children.selected}
                                        {...label} />
                                </div>
                                <div className={styles["policy-details-box"]}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Would you like to add your children to your policy<SharedPopover type={"children"}></SharedPopover></FormLabel>
                                </div>

                            </div>
                            <div className={styles["policy-details-slider-box-container"]}>
                                {insurancePlan.Family.Children.selected &&
                                    <>

                                        {/* <Chip sx={{backgroundColor: '#71bee5', color: 'white'}} */}
                                        <Chip className="min-w-[150px]"
                                            label={`${insurancePlan.Family.Children.amount} ${insurancePlan.Family.Children.amount > 1 ? "Children" : "Child"}` + " Selected"}
                                        />

                                        <Box sx={{ width: 300 }}>
                                            <div className={styles["policy-details-slider-box"]}>
                                                <div className={styles["slider-container-small"]}>
                                                    <SharedSlider
                                                        step={1}
                                                        sliderValue={insurancePlan.Family.Children.amount}
                                                        handleSliderChange={(event) => {
                                                            setInsurancePlan({
                                                                ...insurancePlan, Family: {
                                                                    ...insurancePlan.Family,
                                                                    Children: {
                                                                        ...insurancePlan.Family.Children,
                                                                        amount: event.target.value
                                                                    }
                                                                }
                                                            })
                                                        }

                                                        }
                                                        onblur={() => { }}

                                                        value={insurancePlan.Family.Children.amount}
                                                        min={1}
                                                        max={5}
                                                        text={"children"}
                                                    />
                                                </div>
                                            </div>
                                        </Box>
                                    </>
                                }
                            </div>
                            {/* parents */}
                            <div className={styles["policy-details-box-container"]}>
                                <div className={styles["policy-details-box"]}>
                                    <SharedSwitch toggle={(event) => {
                                        setInsurancePlan({
                                            ...insurancePlan, Parent: {
                                                ...insurancePlan.Parent, selected: event.target.checked
                                            }
                                        })
                                    }}
                                        checked={insurancePlan.Parent.selected}
                                        {...label} />
                                </div>
                                <div className={styles["policy-details-box"]}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Would you like to add your parents to your policy<SharedPopover type={"parent"}></SharedPopover></FormLabel>
                                </div>
                            </div>
                            <div className={styles["policy-details-chip-container"]}>
                                {insurancePlan.Parent.selected && (
                                    <>
                                        {insurancePlan.Parent.parents.map((x, index) => (
                                            <div className={styles["policy-details-chip-content"]} key={index}>
                                                <Chip
                                                    label={`${x.age} year / R ${x.coverAmount} cover`}
                                                    onDelete={() => removeParentChip(x)}
                                                />
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>

                            <div className={styles["policy-details-slider-box-container"]}>
                                {
                                    insurancePlan.Parent.selected &&
                                    <>
                                        {
                                            ageOfParentsCollection.length < 4 &&
                                            <>
                                                <Stack gap={2}>
                                                    <TextField id="standard-basic" label="parent age" value={parentAge} onChange={(event) => {
                                                        let inputAge = Number(event.target.value) || 0;

                                                        if (inputAge > 75) {
                                                            inputAge = 75;
                                                        }
                                                        setParentAge(inputAge);
                                                    }} variant="standard" />
                                                    <TextField id="standard-basic" label="parent cover amount" value={parentCoverAmount} onChange={(event) => {
                                                        let parentCoverAmount = Number(event.target.value) || 0;
                                                        console.log(" more");

                                                        if (parentCoverAmount > insurancePlan.Principal.coverAmount) {
                                                            parentCoverAmount = insurancePlan.Principal.coverAmount
                                                        }

                                                        if (parentCoverAmount > 50000) {
                                                            parentCoverAmount = 50000;
                                                        }

                                                        setParentCoverAmount(parentCoverAmount);
                                                    }}
                                                        variant="standard"
                                                    />
                                                </Stack>
                                                <div className='m-6'>
                                                    <SharedAddButton
                                                        disabled={null}
                                                        text={`Add`}
                                                        handleOnClick={addParentToChip}></SharedAddButton>
                                                </div>
                                                <Box sx={{ width: 400 }}>
                                                    <div className={styles["policy-details-slider-box"]}>
                                                        <div className={styles["slider-container-small"]}>
                                                            <SharedSlider
                                                                sliderValue={parentAge}
                                                                handleSliderChange={(event) => setParentAge(event.target.value)}
                                                                onblur={() => { }}
                                                                min={19}
                                                                max={75}
                                                                value={parentAge}
                                                                step={1}
                                                                text={"Age"}></SharedSlider>
                                                        </div>
                                                        <div className={styles["slider-container-small"]}>
                                                            <SharedSlider
                                                                sliderValue={parentCoverAmount}
                                                                handleSliderChange={(event) => {
                                                                    let parentCoverAmount = Number(event.target.value) || 0;

                                                                    if (parentCoverAmount > insurancePlan.Principal.coverAmount) {
                                                                        parentCoverAmount = insurancePlan.Principal.coverAmount
                                                                    }

                                                                    if (parentCoverAmount > 50000) {
                                                                        parentCoverAmount = 50000;
                                                                    }

                                                                    setParentCoverAmount(parentCoverAmount);
                                                                }}

                                                                onblur={() => { }}
                                                                min={5000}
                                                                step={500}
                                                                max={50000}
                                                                value={parentCoverAmount}
                                                                text={"Cover Amount"}></SharedSlider>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            {/* extended family  */}
                            <div className={styles["policy-details-box-container"]}>
                                <div className={styles["policy-details-box"]}>
                                    <SharedSwitch toggle={(event) => {
                                        setInsurancePlan({
                                            ...insurancePlan, ExtendedFamily: {
                                                ...insurancePlan.ExtendedFamily, selected: event.target.checked
                                            }
                                        })
                                    }}
                                        checked={insurancePlan.ExtendedFamily.selected}
                                        {...label} />
                                </div>
                                <div className={styles["policy-details-box"]}>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Would you like to add any extended family members to your policy<SharedPopover type={"extended"}></SharedPopover></FormLabel>


                                </div>
                            </div>
                            <div className={styles["policy-details-chip-container"]}>
                                {insurancePlan.ExtendedFamily.selected && (
                                    <>
                                        {insurancePlan.ExtendedFamily.extendedMembers.map((x, index) => (
                                            <div className={styles["policy-details-chip-content"]} key={index}>
                                                <Chip
                                                    label={`${x.age} year / R ${x.coverAmount} cover`}
                                                    onDelete={() => removeExtendedFamilyChip(x)}
                                                />
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>

                            <div className={styles["policy-details-slider-box-container"]}>
                                {
                                    insurancePlan.ExtendedFamily.selected &&
                                    <>
                                        {
                                            ageOfExtendedFamilyCollection.length < 4 &&
                                            <>
                                                <Stack gap={2}>
                                                    <TextField id="standard-basic" label="Extended age" value={extendedFamilyAge} onChange={(event) => {
                                                        let inputAge = Number(event.target.value) || 0;

                                                        if (inputAge > 65) {
                                                            inputAge = 65;
                                                        }
                                                        setExtendedFamilyAge(inputAge);
                                                    }}
                                                        variant="standard" />

                                                    <TextField id="standard-basic" label="Extended cover amount" value={extendedFamilyCoverAmount} onChange={(event) => {
                                                        let extendedFamilyCovermount = Number(event.target.value) || 0;
                                               
                                                        if(extendedFamilyCovermount > insurancePlan.Principal.coverAmount){
                                                            extendedFamilyCovermount = insurancePlan.Principal.coverAmount
                                                        }
                                               
                                                        if (extendedFamilyCovermount > 50000) {
                                                            extendedFamilyCovermount = 50000;
                                                        }
                                                
                                                        setExtendedFamilyCoverAmount(extendedFamilyCovermount);
                                                    }}
                                                        variant="standard" />
                                                </Stack>
                                                <div className='m-6'>
                                                    <SharedAddButton
                                                        disabled={null}
                                                        text={`Add`}
                                                        handleOnClick={addExtendedFamilyToChip}></SharedAddButton>
                                                </div>
                                                <Box sx={{ width: 400 }}>
                                                    <div className={styles["policy-details-slider-box"]}>
                                                        <div className={styles["slider-container-small"]}>
                                                            <SharedSlider
                                                                sliderValue={parentAge}
                                                                handleSliderChange={(event) => setExtendedFamilyAge(event.target.value)}
                                                                onblur={() => { }}
                                                                min={1}
                                                                max={65}
                                                                value={extendedFamilyAge}
                                                                step={1}
                                                                text={"Age"}></SharedSlider>
                                                        </div>
                                                        <div className={styles["slider-container-small"]}>
                                                            <SharedSlider
                                                                sliderValue={parentCoverAmount}
                                                                handleSliderChange={(event) => setExtendedFamilyCoverAmount(event.target.value)}
                                                                onblur={() => { }}
                                                                min={5000}
                                                                step={500}
                                                                max={50000}
                                                                value={extendedFamilyCoverAmount}
                                                                text={"Cover Amount"}></SharedSlider>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div >
                </div>
                <div className="sm:w-auto mb-4 sm:mb-0">
                    <div className="sm:w-auto sm:h-full ">
                        <CardTile insurancePlan={calculatePremium()} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Calculator;
