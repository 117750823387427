import React from 'react'
import theme from './theme';
import CancelIcon from '@mui/icons-material/Cancel';

const CancelCheckIcon = () => {
    return (
        <CancelIcon margin sx={{color: theme.tiles.unused, marginRight: '8px' }} />
    )
}

export default CancelCheckIcon