import React from 'react';
import { FormControlLabel, styled, Switch, SwitchProps } from '@mui/material';
import { Controller, FieldValues, Path, PathValue, UseControllerProps } from 'react-hook-form';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: '8px 0',
}));

type FormSwitchProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  control: UseControllerProps<TFieldValues>['control'];
  label: string;
} & Omit<SwitchProps, 'name' | 'checked' | 'defaultChecked' | 'onChange'>;

const FormSwitch = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  ...rest
}: FormSwitchProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false as PathValue<TFieldValues, Path<TFieldValues>>}// Ensuring the default boolean state matches what's expected
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledFormControlLabel
          control={
            <Switch
              checked={value}
              onChange={e => onChange(e.target.checked)}
              {...rest}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default FormSwitch;
